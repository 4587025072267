import http from '@amit/composables/src/useHttp';
import { useApi } from '@amit/composables';
import { collect } from 'collect.js';
import { difference, isEmpty, map, uniqBy } from 'lodash';

export const fetchStudents = group =>
  useApi(http.get('/students', { params: { group } }));

export const fetchEnrollments = (group, course) =>
  useApi(
    http.get('/enrollments/ID/participants', { params: { group, course } }),
  );
export const fetchSubmissions = (group, course) =>
  useApi(http.get('/submissions', { params: { group, course } }));

export const fetchSubmissionsByLesson = (group, lesson) => {
  return useApi(http.get('/submissions', { params: { group, lesson } }));
};

export const fetchStudentSubmissions = (student, exercise) =>
  useApi(http.get('/submissions', { params: { exercise, student } }));

export const setCriteriaScore = (
  enrollmentId: string | number,
  data: {
    criterion: string | number;
    score: number;
  },
) => {
  return useApi(http.put(`/enrollments/${enrollmentId}/criteria`, data));
};

export const reviewSubmission = (submission, data) => {
  return useApi(http.post(`/submissions/${submission}/review`, data));
};

export const reviewFeedbackAI = (submissionId: string) => {
  return useApi(http.post('/llm/review', { submission: submissionId }));
};

export const setSubmissionScore = (submission, data) => {
  return useApi(http.post(`/submissions/${submission}/score`, data));
};

export const getStudents = id => useApi(http.get(`/users/${id}`));

export const unlockLesson = (group, course, lesson, unlock_at) => {
  return useApi(
    http.post(`/groups/${group}/enrollments/unlock`, {
      course,
      lesson,
      unlock_at,
    }),
  );
};

export const sendNotification = (data: any) => {
  return useApi(http.post('/notifications', data));
};

export const buildDashboardSummaryData = (
  submissions: any,
  students: any,
  lessonId = '',
) => {
  const studentsIds = uniqBy(map(students, 'id'), 'id');
  const startedStudents = uniqBy(map(submissions, 'student.id'), 'id');
  const nonEnrolledStudents = difference(studentsIds, startedStudents);

  const finishedSubmissions = submissions.filter(
    submission => submission.status === 'finished',
  );
  let filteredSubmissions;
  if (lessonId) {
    filteredSubmissions = finishedSubmissions.filter(lesson => {
      return lesson.lesson.id === lessonId;
    });
  } else {
    filteredSubmissions = finishedSubmissions;
  }

  const todaySubmissions = filteredSubmissions.filter(submission => {
    const today = new Date().setHours(0, 0, 0, 0);
    const thatDay = new Date(submission.created_on).setHours(0, 0, 0, 0);
    return today === thatDay;
  });

  return [
    {
      title: 'לא התחילו',
      count: nonEnrolledStudents.length,
      delta: nonEnrolledStudents.length,
      status: 'danger',
      color: '#E4EAF2',
    },
    {
      title: 'ללא חריגות',
      count: filteredSubmissions.filter(progress => progress.score >= 75)
        .length,
      delta: todaySubmissions.filter(progress => progress.score >= 75).length,
      status: 'success',
      color: '#29CC39',
    },
    {
      title: 'מעט חריגות',
      count: filteredSubmissions.filter(
        progress => progress.score >= 50 && progress.score < 75,
      ).length,
      delta: todaySubmissions.filter(
        progress => progress.score >= 50 && progress.score < 75,
      ).length,
      status: 'notice',
      color: '#FFD400',
    },
    {
      title: 'דורש התייחסות',
      count: filteredSubmissions.filter(
        progress => progress.score >= 25 && progress.score < 50,
      ).length,
      delta: todaySubmissions.filter(
        progress => progress.score >= 25 && progress.score < 50,
      ).length,
      status: 'warning',
      color: '#F59E0B',
    },
    {
      title: 'חריגות רבות',
      count: filteredSubmissions.filter(progress => progress.score < 25).length,
      delta: todaySubmissions.filter(progress => progress.score < 25).length,
      status: 'danger',
      color: '#FF4B81',
    },
  ];
};

export const buildAwaitingFeedbackItems = (submissions = '') => {
  return collect(submissions)
    .filter((submission: any) => submission.status === 'awaiting_review')
    .groupBy('exercise.title')
    .map((data: any) => {
      const submissions = data.toArray();
      const firstSubmission = submissions[0];
      return {
        id: firstSubmission.id,
        lesson_id: firstSubmission.lesson.id,
        exercise: firstSubmission.exercise.title,
        moreMembers: submissions.length - 3 > 0 ? submissions.length - 3 : 0,
        members: submissions.map((submission: any) => {
          return {
            image:
              submission.student.avatar ||
              'https://avatars.dicebear.com/api/croodles/your-custom-seed.svg',
            name: submission.student.fullName,
          };
        }),
      };
    })
    .all();
};

export const buildProgressItems = (enrollments: any) => {
  return collect(enrollments)
    .reject((enrollment: any) => enrollment.summary.length <= 0)
    .map((enrollment: any) => {
      const lastSummary: any = collect(enrollment.summary)
        .where('progress', '!==', 0)
        .last();
      if (!lastSummary) {
        return {};
      }
      const lastSection: any = collect(lastSummary.sections).last();
      if (!lastSection) {
        return {};
      }
      return {
        ...enrollment,
        summary: {
          ...lastSummary,
          section: lastSection,
        },
        title: lastSummary.lesson.title + ' | ' + lastSection.title,
      };
    })
    .groupBy('title')
    .map((data: any) => {
      const enrollments = data.toArray();
      const firstEnrollment = enrollments[0];
      if (isEmpty(firstEnrollment)) {
        return null;
      }
      return {
        id: firstEnrollment.id,
        title: firstEnrollment.title,
        moreMembers: enrollments.length - 3 > 0 ? enrollments.length - 3 : 0,
        members: collect(enrollments)
          .reject(enrollment => isEmpty(enrollment))
          .map((enrollment: any) => {
            return {
              image:
                enrollment.student.avatar ||
                'https://avatars.dicebear.com/api/croodles/your-custom-seed.svg',
              name: enrollment.student.fullName,
            };
          }),
      };
    })
    .filter((item: any) => item)
    .take(3)
    .all();
};

export const saveUser = (user: any) => {
  return useApi(http.post(`/users/${user.id}`, { user }));
};

export const createUser = (user: any, schoolId: string) => {
  return useApi(
    http.post('/users', {
      user,
      school_id: schoolId,
    }),
  );
};

export const softDeleteUser = (user: any) => {
  return useApi(http.delete(`/users/${user.id}`));
};

export const lostUsers = () => {
  return useApi(http.get('/users/lost'));
};
