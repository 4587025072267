import { defineStore } from 'pinia';
import {
  buildDashboardSummaryData,
  buildProgressItems,
  fetchEnrollments,
  fetchStudents,
  fetchSubmissions,
  fetchSubmissionsByLesson,
  lostUsers,
} from '/@/app/services/students';
import { fetchEnrollments as fetchProjectEnrollments } from '/@/app/services/projects';
import { useAccountStore } from '/@/app/store/account';
import { fetchCourseModule } from '/@/app/services/groups';

import { collect } from 'collect.js';
import { IGate } from '/@/app/models/interfaces';
import { AxiosResponse } from 'axios';
import { listGroupsBySchool } from '/@/app/services/accounts';
import { getSurveyEnrollments } from '/@/app/services/surveys';

export const useStudentsStore = defineStore({
  id: 'students',
  state: () => ({
    students: [] as any[],
    enrollments: [] as any[],
    projectEnrollments: [] as any[],
    surveyEnrollments: [] as any[],
    courseModules: [] as any[],
    submissions: [] as any[],
    skills: [] as any[],
    selectedSkill: {} as any,
    selectedCourse: {} as any,
    availableGates: [] as IGate[],
    dashboardSummaryData: [] as any[],
    progressSummaryData: [] as any[],
    selectedLesson: '' as any,
    awaitingFeedbackItems: [] as any,
    progressItems: [] as any,
    groups: [] as any[],
    lostUsers: [] as any,
    isLoadingEnrollments: false,
  }),
  getters: {
    lessonsOptions: (state: any) =>
      state.selectedCourse.outline
        ? state.selectedCourse.outline.map(({ lesson, id, title }) => ({
            title,
            value: id,
            number: lesson,
          }))
        : [],
    currentLesson: (state: any) => {
      if (!state.selectedCourse.outline) {
        return null;
      }
      return state.selectedCourse.outline.find(
        lesson => lesson.id === state.selectedLesson,
      );
    },
  },
  actions: {
    async init() {
      let accountStore = useAccountStore();
      let { asPromised: studentsRequest } = fetchStudents(
        accountStore.selectedGroup.id,
      );

      this.students = [];
      studentsRequest().then((data: { data: AxiosResponse }) => {
        this.students = data.data;

        this.availableGates = [
          { id: '1', title: 'שער הדיאלוג' },
          { id: '2', title: 'שער העצמאות' },
          { id: '3', title: 'שער הגילוי' },
        ];

        this.groups = collect(accountStore.groups)
          .sortBy('name')
          .map((group: any) => {
            return {
              title: group.name,
              value: group.id,
            };
          })
          .toArray();
      });

      //FIXME: We need to deal also with store and facilities ...
      let { onSuccess: onSuccessGroupsRequest } = listGroupsBySchool(
        accountStore.user.school.id,
      );
      onSuccessGroupsRequest(data => {
        this.groups = data.data;
      });
    },

    fetchEnrollments({ group, course }) {
      let { onSuccess } = fetchEnrollments(group, course);

      onSuccess(({ data }) => {
        this.enrollments = data;
        this.progressItems = buildProgressItems(data);
        const accountStore = useAccountStore();
        const singleEnrollment = collect(this.enrollments)
          .where('group', group)
          .where('course.id', course)
          .first();
        accountStore.setSelectedCourseOutline(singleEnrollment);
      });
    },

    fetchProjectEnrollments({ group, project }) {
      const { onSuccess } = fetchProjectEnrollments(project, group);

      onSuccess(({ data }) => {
        this.projectEnrollments = data;
      });
    },

    fetchSurveyEnrollments({ group, survey }) {
      let { onSuccess } = getSurveyEnrollments({ group, survey });

      onSuccess(({ data }) => {
        this.surveyEnrollments = data;
      });
    },

    setCourseModule(group, course) {
      const { onSuccess } = fetchCourseModule({
        group,
        course,
      });
      onSuccess(({ data }) => {
        const courseModule = data?.[0];
        if (!courseModule) {
          return;
        }

        const index = this.courseModules.findIndex(
          module => module.group === group && module.course.id === course,
        );
        if (index > -1) {
          this.courseModules[index] = courseModule;
        } else {
          this.courseModules.push(courseModule);
        }
      });
    },

    getCourseModule(group, course) {
      return this.courseModules.find(module => {
        return module.group === group && module.course.id === course;
      });
    },

    clearCourseModule() {
      this.courseModules = [];
    },

    clearSurveyEnrollments() {
      this.surveyEnrollments = [];
    },

    fetchSubmissions(group, course) {
      let { onSuccess } = fetchSubmissions(group, course);
      onSuccess(({ data }) => {
        this.submissions = data;
      });
    },
    fetchSubmissionsByLesson(group, lesson) {
      let { onSuccess } = fetchSubmissionsByLesson(group, lesson);
      onSuccess(({ data }) => {
        this.submissions = data;
      });
    },

    getLostUsers() {
      if (this.lostUsers.length) return;
      const { onSuccess, onError } = lostUsers();
      onSuccess(({ data }) => {
        this.lostUsers = data;
      });
      onError(e => {
        console.log(e);
      });
    },

    changeLesson(selectedLesson) {
      this.selectedLesson = selectedLesson;
      this.dashboardSummaryData = buildDashboardSummaryData(
        this.submissions,
        this.students,
        selectedLesson,
      );
    },

    updateSubmission({ submissionId, data }) {
      const submissionIndex = this.submissions.findIndex(
        s => s.id === submissionId,
      );
      if (submissionIndex !== -1) {
        this.submissions[submissionIndex] = {
          ...this.submissions[submissionIndex],
          ...data,
        };
      }
    },
  },
});
